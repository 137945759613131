* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	color: #000;
	background: #c6377b;
	/* background-image: url(../assets/images/background-header.svg); */
	background-repeat: no-repeat;
	background-position: top;
}

body, input, button, textarea {
	font: 600 18px;
}

body {
	-webkit-font-smoothing: antialiased !important;
}

body, input, button, textarea, select {
	font: 600 12px Montserrat, sans-serif !important;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
}

button {
	cursor: pointer;
}

input {
	border-radius: 6px;
	background-color: transparent;
	font-size: 18px !important;
	padding: 20px !important;
	height: 51px;
	font-size: 12px !important;
	color: #101633 !important;
	font-weight: 600 !important;
	border: 0px solid;
	transition: border 0.2s ease 0s;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px #121214 inset;
	-webkit-text-fill-color: white !important;
}

input:-webkit-autofill {
	-webkit-animation-delay: 1s;
	/* Safari support - any positive time runs instantly */
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
}

textarea {
	color: #fff;
	border: 2px solid #121214;
	transition: border 0.2s ease 0s;
}

input:focus, textarea:focus {
	outline: none;
	border: 0 solid;
}

.btn-primary {
	width: 100%;
	background: rgb(43 59 133) !important;
	border-radius: 5px;
	height: 41px;
	color: rgb(255, 255, 255);
	font-weight: bold !important;
	transition: all 0.2s ease 0s, color 0.2s ease 0s;
	font-size: 12px;
	border: none;
}

.btn {
	font-weight: bold !important;
	font-size: 12px;
}

.btn-primary:hover {
	background: rgb(23 40 119) !important;
}

select {
	height: 60px;
	background: rgb(196 196 196);
	border-radius: 5px;
	padding: 20px;
	margin: 3px 0;
	font-size: 12px;
}

.modal-content {
	border-radius: 20px;
}

.modalslide .modal-content {
	transform: translateY(5%);
	background: transparent !important;
}